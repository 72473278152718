
import { defineAsyncComponent, defineComponent, PropType } from "vue";

const EmojiPicker = defineAsyncComponent(
  () => import("./emoji-picker/EmojiPicker.vue")
);
const GifPicker = defineAsyncComponent(
  () => import("./gif-picker/GifPicker.vue")
);

export default defineComponent({
  components: { EmojiPicker, GifPicker },
  emits: ["close", "click"],
  props: {
    inputElement: Object,
    buttonClassName: {
      type: String,
    },
    defaultTab: {
      type: String as PropType<"EMOJI" | "GIF">,
      default: "EMOJI",
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      tab: this.defaultTab,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    backgroundClick(event) {
      if (!event.target.closest(this.buttonClassName)) this.close();
    },
  },
});
